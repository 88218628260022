<template>
  <k-layout no-footer :navProps="{ variant: 'dark' }">
    <!-- Skeleton -->
    <div v-if="isStoreState('loadingDocument', 'PENDING') || !event">
      <div class="tw-bg-gray-900 tw-pt-20 tw-pb-12 tw-px-4">
        <k-container class="tw-py-6 sm:tw-py-12">
          <v-skeleton-loader dark type="heading" />
          <v-skeleton-loader dark type="text" class="tw-max-w-xs tw-mt-4" />
          <div class="tw-flex tw-space-x-2 tw-mt-6">
            <v-skeleton-loader dark type="button" />
            <v-skeleton-loader dark type="button" />
          </div>
          <div class="tw-flex tw-space-x-2 tw-mt-6">
            <v-skeleton-loader dark type="text" class="tw-w-12" />
            <v-skeleton-loader dark type="text" class="tw-w-12" />
            <v-skeleton-loader dark type="text" class="tw-w-12" />
          </div>
          <v-skeleton-loader dark type="text" class="tw-w-48 tw-mt-6" />
        </k-container>
      </div>

      <main class="tw-mt-8 tw-px-4 tw-mb-24">
        <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-lg">
          <div class="tw-flex tw-justify-center">
            <v-progress-circular :size="48" class="tw-text-gray-600" indeterminate></v-progress-circular>
          </div>
        </div>
      </main>
    </div>

    <div v-else class="tw-h-screen">
      <section class="tw-bg-gray-900">
        <k-container class="tw-py-6 sm:tw-py-12">
          <h1 class="tw-heading-1 tw-text-gray-50">{{ event.name }}</h1>
          <p class="tw-text-gray-300 tw-text-base tw-font-semibold tw-mt-4">
            <span class="tw-uppercase">{{ formatRange(event.startDate, event.endDate)
              }}{{ event.city ? `, ${ event.city }` : '' }}</span>
          </p>

          <div class="tw-mt-8 tw-text-white tw-flex tw-space-x-2">
            <k-button @click="downloadIcs">
              {{ $t('event.detail.action') }}
            </k-button>
          </div>

          <ul class="tw-list-none tw-p-0 tw-space-x-2 tw-flex tw-mt-6">
            <li v-for="topic in event.topics" :key="topic">
              <k-technology-chip :technology="topic" size="lg" variant="dark" />
            </li>
          </ul>

          <p class="tw-mt-6">
            <a
              :href="externalURL"
              target="_blank"
              ref="noopener noreferrer"
              class="tw-text-white tw-text-base"
            >{{ event.url }}</a>
          </p>
        </k-container>
      </section>
      <iframe width="100%" height="100%" :src="externalURL" frameborder="0" class="tw-w-full tw-h-full"></iframe>
    </div>
  </k-layout>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  created() {
    const { id } = this.$route.params
    this.$store.dispatch('EVENTS/getEventById', id)
    this.$vuetify.goTo(0)
  },
  computed: {
    ...mapGetters('EVENTS', ['eventById', 'isStoreState']),
    event() {
      const { id } = this.$route.params
      const event = this.eventById(id)
      return event
    },
    externalURL() {
      if (this.event.url) {
        const url = new URL(this.event.url)
        url.searchParams.append('utm_source', 'Koderia.sk')
        return url.toString()
      } else {
        return ''
      }
    }
  },
  methods: {
    notFound() {
      this.$router.push({ name: 'Page404', params: { '0': '' } }).catch(() => {
      })
    },
    formatRange(date1, date2) {
      let fmt = new Intl.DateTimeFormat('sk', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })

      return fmt.formatRange(new Date(date1), new Date(date2))
    },
    downloadIcs() {
      const link = document.createElement('a')
      link.setAttribute('href', window.location.origin + '/podujatia/' + this.$route.params.id + '.ics')
      link.setAttribute('target', '_blank')
      link.setAttribute('rel', 'noopener noreferrer')
      link.setAttribute('download', 'event.ics')
      link.dispatchEvent(new MouseEvent('click'))
    }
  }
}
</script>
