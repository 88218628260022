import { render, staticRenderFns } from "./event-detail.vue?vue&type=template&id=5ea10e94&"
import script from "./event-detail.vue?vue&type=script&lang=js&"
export * from "./event-detail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KButton from '@web/components/core/k-button'
import KContainer from '@web/components/core/k-container'
import KLayout from '@web/components/core/k-layout'
import KTechnologyChip from '@web/components/core/k-technology-chip'
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {KButton,KContainer,KLayout,KTechnologyChip,VProgressCircular,VSkeletonLoader})
